<script>
    import { getContext } from "svelte";
    import { writable } from "svelte/store";
    import { CartItem } from "./cart";
    import Extras from "./Extras.svelte";
    import { EUR, displayVariant } from "./util";

    const cart = getContext("cart");

    export let item = {};
    export let category = {};
    export let onAdded;
    let instructions = "";

    $: cartItem.update((item) => {
        item.instructions = instructions.trim();
        return item;
    });

    let extras = writable(new Set());

    const cartItem = writable(
        new CartItem(item, 1, 0, [], category.required_options?.[0]),
    );
    $: cartItem.update((item) => {
        $cartItem.extras = [...$extras];
        return item;
    });
</script>

<!-- svelte-ignore a11y-click-events-have-key-events a11y-no-static-element-interactions  -->
<div class="border-t-2 border-gray-400">
    <h6 class="font-bold mt-2 mb-4">In den Warenkorb</h6>

    {#if category.required_options && !["89"].includes(item.id)}
        {#each category.required_options as option, i}
            <label>
                {displayVariant(option)}
                <input
                    type="radio"
                    name="option"
                    class="mr-2"
                    value={i}
                    checked={i === 0}
                    on:change={(event) => {
                        cartItem.update((s) => {
                            s.variant =
                                category.required_options[event.target.value];
                            return s;
                        });
                    }}
                />
            </label>
        {/each}
    {/if}
    <div class="my-1">
        <span class="mr-4">Anzahl:</span>
        <button
            class="bg-green-300 rounded-full p-2 w-10 h-10"
            on:click={() => {
                cartItem.update((s) => {
                    s.quantity += 1;
                    return s;
                });
            }}>+</button
        >
        <span class="mx-8">{$cartItem.quantity}</span>
        <button
            type="button"
            class="bg-green-300 rounded-full p-2 w-10 h-10"
            on:click={() => {
                $cartItem.quantity > 1 &&
                    cartItem.update((s) => {
                        s.quantity -= 1;
                        return s;
                    });
            }}
        >
            -
        </button>
    </div>
    {#if item.prices.length > 1}
        <br />
        <div class="my-1">
            <span class="mr-4">Größe:</span>

            {#each item.prices as price, i}
                <button
                    class="block xl:inline-block py-2 text-center w-max px-6 rounded-none
                        {i === $cartItem.size
                        ? 'bg-green-600 text-white'
                        : 'hover:bg-green-600 hover:text-white'}
                        border border-green-600
                        {i !== 0 && 'xl:border-l-0'}
                        "
                    on:click={() => {
                        cartItem.update((s) => {
                            s.size = i;
                            return s;
                        });
                    }}
                >
                    {#if category.size_info[i]}
                        {category.size_info[i]} -
                    {/if}
                    {EUR.format(price)}
                </button>
            {/each}
        </div>
        <br />
    {/if}
    {#if !["65", "65a"].includes(item.id)}
        <Extras
            bind:selected={extras}
            size={$cartItem.size}
            supported={category.supported_extras}
        />
    {/if}

    <section>
        <label for="instructions" class="block">Anmerkungen:</label>
        <textarea
            bind:value={instructions}
            placeholder="Anmerkungen"
            class="w-full max-h-200"
        ></textarea>
    </section>

    <section>
        <span>Gesamt: <strong>{EUR.format($cartItem.price)}</strong> </span>
        {#if $cart}
            <button
                class="bg-green-600 text-white p-2 rounded-md float-end"
                on:click={() => {
                    cart.update((cart) => {
                        cart.add($cartItem);
                        return cart;
                    });

                    onAdded();
                }}
            >
                In den Warenkorb
            </button>
        {/if}
    </section>
</div>
