<script>
    import { getContext } from "svelte";
    import { writable } from "svelte/store";
    import { EUR, EXTRA_PRICES } from "./util";

    export let selected = writable(new Set());
    export let supported = [];
    export let size = 0;

    const menu = getContext("menu");
</script>

{#if supported.length}
    <details>
        <summary>Wählen Sie Ihre Extrazutaten</summary>
        <!-- svelte-ignore a11y-click-events-have-key-events a11y-no-static-element-interactions -->
        <div
            class="h-64 overflow-y-auto"
            on:click={(event) => event.stopPropagation()}
        >
            {#await $menu then menu}
                {#each Array.from(menu.ingredients.values()).filter( (ingredient) => supported.includes(ingredient.type), ) as extra}
                    {#if !extra.hide}
                        <div class="flex">
                            <input
                                type="checkbox"
                                id={extra.id}
                                on:change={() => {
                                    selected.update((s) => {
                                        if (s.has(extra)) {
                                            s.delete(extra);
                                        } else {
                                            s.add(extra);
                                        }
                                        return s;
                                    });
                                }}
                                class="mr-2"
                            />
                            <label for={extra.id}
                                >{extra.name} (+ {EUR.format(
                                    EXTRA_PRICES[extra.type][size],
                                )})</label
                            >
                        </div>
                    {/if}
                {/each}
            {/await}
        </div>
    </details>
{/if}
