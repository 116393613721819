<script>
    import { setContext } from "svelte";
    import { readable, writable } from "svelte/store";
    import CartList from "./lib/Cart.svelte";
    import Header from "./lib/Header.svelte";
    import MenuList from "./lib/MenuList.svelte";
    import Nav from "./lib/Nav.svelte";

    import { Cart } from "./lib/cart";
    import Checkout from "./lib/Checkout.svelte";
    import Cookies from "./lib/Cookies.svelte";
    import Footer from "./lib/Footer.svelte";
    import { Menu } from "./lib/menu";

    const menu = setContext("menu", readable(Menu.load()));

    let checkout = false;

    let zipSelected = localStorage.getItem("zip");
    let zip = zipSelected;

    setContext(
        "cart",
        writable(null, (set) => {
            Cart.load($menu).then(set);
        }),
    );

    let currentCategory = (() => {
        const hash = window.location.hash;
        if (hash == "#checkout") {
            return null;
        } else if (hash) {
            return decodeURIComponent(hash.slice(1));
        } else {
            return "pizza";
        }
    })();
</script>

<Header>
    <Nav onNav={(target) => (currentCategory = target)} />
</Header>
{#if zipSelected}
    <Checkout bind:show={checkout} />
    <div class="lg:flex lg:flex-row-reverse sticky">
        <aside class="lg:flex-initial mr-3 mx-12 my-2 p-5">
            <div class="lg:sticky top-0 left-0 right-0 p-16">
                <CartList onCheckout={() => (checkout = true)} />
            </div>
        </aside>
        <main class="lg:flex-grow">
            <MenuList categoryId={currentCategory} />
        </main>
    </div>
    <Footer />
{:else}
    <div class="py-20 grid place-items-center">
        <h1 class="text-lg font-bold">Was ist ihre Postleitzahl?</h1>
        <p>Bitte wählen sie Ihr Liefergebiet.</p>
        <label
            for="zip"
            class="hidden md:flex items-center flex-initial text-lg w-32 m-4"
            >PLZ:</label
        >
        <select
            class="flex-1 p-4 m-2"
            id="zip"
            name="zip"
            required
            bind:value={zip}
        >
            <optgroup label="Oberhausen">
                <option disabled selected value={null}>Bitte Auswählen</option>
                <option value="46047">46047 </option>
                <option value="46045">46045 </option>
                <option value="46049">46049</option>
            </optgroup>
            <optgroup label="Mülheim a.d.R">
                <option value="45473">45473 </option></optgroup
            >
            <optgroup label="Essen">
                <option value="45359">45359</option>
            </optgroup>
        </select>
        <button
            disabled={!zip}
            class="p-4 m-8 bg-blue-500 text-white disabled:opacity-50"
            on:click={() => {
                localStorage.setItem("zip", zip);
                zipSelected = zip;
            }}>OK</button
        >
    </div>
{/if}
<Cookies />
