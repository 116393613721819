<script>
    export let toggle = true;
    export let labels = ["True", "False"];
</script>

<div class="flex place-items-center my-4 w-100">
    <button
        on:click={() => (toggle = true)}
        type="button"
        class="border border-gray-100 hover:bg-gray-100 px-5 py-2 rounded-l-md flex-1 {toggle &&
            'bg-gray-100 border-green-500'}">{labels[0]}</button
    >
    <button
        on:click={() => {
            toggle = false;
        }}
        type="button"
        class="border border-gray-300 hover:bg-gray-100 px-5 py-2 rounded-r-md flex-1 {!toggle &&
            'bg-gray-100 border-green-500'}">{labels[1]}</button
    >
</div>
