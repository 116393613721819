<script>
    // @ts-nocheck

    import { getContext } from "svelte";
    import AddToCart from "./AddToCart.svelte";
    import { EUR } from "./util";

    const menu = getContext("menu");

    export let categoryId;
    let category;
    $: category = $menu.then((menu) => menu.categories.get(categoryId));
    let disabledItems = fetch("/api/disabled_items")
        .then((res) => res.json())
        .then((json) => {
            console.log(json);
            return json;
        });
    let selected = null;
</script>

{#await Promise.all([category, disabledItems]) then [category, disabledItems]}
    <section class="my-4">
        <h3 class="text-xl ml-6 font-headers">
            {category.name}
        </h3>
        {#each category.items as item}
            <!-- svelte-ignore a11y-click-events-have-key-events a11y-no-static-element-interactions  -->
            <section
                role="button"
                tabindex="0"
                on:click={() => {
                    if (!disabledItems.includes(item.id)) {
                        selected = item;
                    }
                }}
                class="container group rounded-md shadow-2xl drop-shadow-md my-8 mx-2 max-w-full md:mx-8 p-5 {selected ===
                item
                    ? 'bg-green-50 p-12'
                    : disabledItems.includes(item.id)
                      ? 'bg-gray-200'
                      : 'hover:bg-green-50 hover:p-12 '}  transition-all duration-75 ease-in-out"
            >
                <h4 class="text-lg">{item.name}</h4>
                <h5 class="text-gray-600">#{item.id}</h5>
                <h6 class="text-gray-600 text-sm">{item.allergy_info}</h6>
                {#if item.description}
                    <p>{item.description}</p>
                {/if}
                <br />

                {#if item === selected}
                    <button
                        class="absolute top-0 px-4 py-2 text-center align-middle right-0 text-red-500 hover:text-white hover:bg-red-500 rounded-bl-md rounded-tr-md"
                        on:click={(event) => {
                            event.stopPropagation();
                            selected = null;
                        }}>Abbrechen</button
                    >
                    <AddToCart
                        {item}
                        onAdded={() => {
                            event.stopPropagation();
                            selected = null;
                        }}
                        {category}
                    />
                {:else if !disabledItems.includes(item.id)}
                    <button
                        class="hidden group-hover:block absolute top-0 px-4 py-2 text-center align-middle right-0 text-white bg-green-500 rounded-bl-md rounded-tr-md"
                        >Hinzufügen</button
                    >
                    <span class="text-gray-600">
                        {#each item.prices as price, i}
                            {#if i !== 0}
                                ⋅
                            {/if}
                            {EUR.format(price)}
                        {/each}
                    </span>
                {:else}
                    <p class="text-red">Momentan nicht verfügbar</p>
                {/if}
            </section>
        {/each}
    </section>
{/await}
