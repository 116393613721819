<script>
    import { getContext } from "svelte";

    const menu = getContext("menu");

    export let onNav;

    function scrollTo(id) {
        onNav(id);
    }
</script>

{#await $menu then menu}
    <nav class="xl:flex xl:items-stretch xl:flex-nowrap xl:overflow-x-auto">
        {#each menu.categories.values() as category}
            <a
                href="#{encodeURIComponent(category.id)}"
                class="xl:flex-1 block button hover:bg-green-600 hover:text-gray-100 transition-all ease-in-out duration-200 rounded-md p-2 m-2"
                on:click={() => {
                    onNav(category.id);
                }}>{category.name}</a
            >
        {/each}
    </nav>
{/await}
