import { EXTRA_PRICES } from "./util";

//@ts ignore
const ITEM_REGEX = /(?:(\d+)x)?([0-9a-zA-Z]+)(?:@(\d))?/;

export class Cart {
  constructor(menu, items = []) {
    this.menu = menu;
    this.items = items;
  }

  save() {
    localStorage.setItem("cart", JSON.stringify(this.toJson()));
  }

  add(item) {
    console.log(item);
    this.items.push(item);
    this.save();
  }

  remove(index) {
    this.items.splice(index, 1);
    this.save();
  }

  clear() {
    this.items = [];
    this.save();
  }

  get total() {
    return this.items.reduce((total, item) => total + item.price, 0);
  }

  get empty() {
    return this.items.length === 0;
  }

  toJson() {
    return this.items.map((item) => {
      return {
        ...item,
        item: item.item.id,
        variant: item.variant ?? undefined,
        extras: item.extras.map((extra) => extra.id),
      };
    });
  }

  static async load(menuPromise) {
    let cache = localStorage.getItem("cart");
    let menu = await menuPromise;
    if (cache) {
      let items = JSON.parse(cache).map((itemData) => {
        let item = menu.items.get(itemData.item);
        let extras = itemData.extras.map((extra) =>
          menu.ingredients.get(extra),
        );
        return new CartItem(
          item,
          itemData.quantity,
          itemData.size,
          extras,
          itemData.variant,
          itemData.instructions,
        );
      });
      return new Cart(menu, items);
    }
    return new Cart(menu);
  }
}

export class CartItem {
  constructor(item, quantity = 1, size = 0, extras = [], variant = null, instructions = "") {
    this.item = item;
    this.quantity = quantity;
    this.size = size;
    this.extras = extras;
    this.variant = variant;
    this.instructions = instructions;
  }

  get name() {
    return this.item.name;
  }

  get price() {
    let base = this.item.prices[this.size];
    for (let extra of this.extras) {
      console.log(EXTRA_PRICES, extra);
      base += EXTRA_PRICES[extra.type][this.size];
    }

    return base * this.quantity;
  }
}
