<script>
    import { fly } from "svelte/transition";

    export let value = "";
    export let label = "";
    export let id = "";
    export let type = "text";
    export let required = true;
    export let maxlength = 100;
    export let validate = (value) => null;
    let error_message = required ? "Bitte füllen Sie dieses Feld aus." : "";

    function setIfValid(newValue) {
        if (required && !newValue.trim()) {
            error_message = "Bitte füllen Sie dieses Feld aus.";

            return;
        }
        let validation = validate(newValue);
        if (typeof validation === "string") {
            error_message = validation;
            value = null;
        } else {
            error_message = "";
            value = newValue;
        }
    }
</script>

<div class="flex place-items-center h-16">
    <label for={id} class="hidden md:inline flex-initial my-2 text-lg w-32"
        >{label}:
    </label>
    <div class="flex-1 flex-col flex h-16">
        <input
            {type}
            {id}
            class="flex-1 my-2 border-b outline-none px-4 {error_message
                ? 'h-10'
                : 'h-16'} mx-2 transition-all duration-300 {error_message
                ? 'border-red-500 placeholder-red-500'
                : value
                  ? 'border-green-500 '
                  : 'border-gray-300'}"
            name={id}
            {maxlength}
            placeholder={label}
            {required}
            on:input={(event) => setIfValid(event.target.value)}
            on:change={(event) => setIfValid(event.target.value)}
        />
        {#if error_message}
            <span
                transition:fly={{ y: 20, duration: 300 }}
                class="text-red-500 grow row-span-1 text-xs align-middle"
                >{error_message}</span
            >
        {/if}
    </div>
</div>
