import { API } from "./util";

const ITEM_REGEX = /(\d+)?x([0-9a-zA-Z]+)(?:@(\d))?/;

export class Menu {
  constructor(menu, ingredients) {
    console.log(ingredients);
    this.ingredients = new Map(
      ingredients.map((ingredient) => [ingredient.id, ingredient]),
    );
    this.categories = new Map(menu.map((category) => [category.id, category]));
    this.items = new Map(
      menu.flatMap((category) => category.items.map((item) => [item.id, item])),
    );
    this.save();
  }

  static async load() {
    let cache = localStorage.getItem("menu");
    if (cache) {
      let ingredients = localStorage.getItem("ingredients");
      return new Menu(JSON.parse(cache), JSON.parse(ingredients));
    }

    let menu = await fetch(`${API}/menu/categories`);
    menu = await menu.json();

    let ingredients = await fetch(`${API}/ingredients`);

    ingredients = await ingredients.json();
    return new Menu(menu, ingredients);
  }

  save() {
    localStorage.setItem(
      "menu",
      JSON.stringify(Array.from(this.categories.values())),
    );
    localStorage.setItem(
      "ingredients",
      JSON.stringify(Array.from(this.ingredients.values())),
    );
  }
}
