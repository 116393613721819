<script>
    let shouldShow = localStorage.getItem("cookie-consent") !== "true";
</script>

{#if shouldShow}
    <div
        class="fixed p-2 h-fit left-0 right-0 bottom-0 bg-green-700 text-white"
    >
        <em>
            Diese Webseite verwendet Cookies, um das Aufgeben von Bestellungen
            zu ermöglichen. {" "}
            Durch die Nutzung dieser Seite erklären Sie sich mit der Verwendung von
            technisch notwendigen Cookies einverstanden.
            <button
                class="py-4 px-2 shadow-md"
                on:click={() => {
                    shouldShow = false;
                    localStorage.setItem("cookie-consent", "true");
                }}>OK</button
            >
        </em>
    </div>
{/if}
