<script>
    import { loadScript } from "@paypal/paypal-js";
    import { getContext } from "svelte";
    import Cart from "./Cart.svelte";
    import CheckoutInput from "./CheckoutInput.svelte";
    import Toggle from "./Toggle.svelte";
    import { API, PAYPAL_CLIENT_ID } from "./util";

    const cart = getContext("cart");

    let canDeliver;

    $: canDeliver = $cart?.total >= 8;

    export let show = false;
    let delivery = canDeliver;
    let dialog;

    let name = null,
        tel = null;

    let street = null,
        houseNumber = null;

    let comment = "";

    let cash = true;
    let paypalOrderId = null;

    let wantsDate = false,
        date = null;

    $: if (dialog && show) dialog.showModal();

    let canSubmit = false;
    $: canSubmit = isFormValid({
        name,
        tel,
        street,
        houseNumber,
        cash,
        date,
        paypalOrderId,
    });

    function isFormValid({
        name,
        tel,
        street,
        houseNumber,
        cash,
        date,
        paypalOrderId,
    }) {
        return (
            name &&
            tel &&
            (delivery ? street && houseNumber : true) &&
            (cash || paypalOrderId) &&
            (wantsDate ? isValidDate(date) : true)
        );
    }

    function isValidDate(dateStr) {
        const date = new Date(dateStr);
        const now = new Date();
        if (!date || date < now) {
            return false;
        } else if (date.getHours() < 12) {
            return false;
        } else if (date.getDay() === 0) {
            return date.getHours() <= 21 && date.getMinutes() <= 30;
        } else {
            return date.getHours() <= 22;
        }
    }

    async function submitOrder() {
        console.log(date);
        const order = {
            name,
            tel,
            cart: $cart.toJson(),
            comment: comment.trim(),
            date: wantsDate ? new Date(date).getTime() : null,
        };
        if (delivery) {
            order.address = {
                street,
                house_number: houseNumber,
                zip: localStorage.getItem("zip"),
            };
        }
        if (cash) {
            order.payment = "Cash";
        } else {
            order.payment = {
                Paypal: paypalOrderId,
            };
        }
        console.log(order);
        const response = await fetch(`${API}/submit`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(order),
        });
        if (response.ok) {
            location.href = `/checkout-successful.html#${delivery ? "delivery" : "pickup"}`;
        } else {
            alert(
                "Fehler beim Aufgeben der Bestellung. Bitte bestellen Sie per Telefon.",
            );
        }
    }

    loadScript({
        "client-id": PAYPAL_CLIENT_ID,
        currency: "EUR",
    }).then((paypal) => {
        paypal
            .Buttons({
                async createOrder(data, actions) {
                    console.log("Made it here");

                    const response = await fetch(`${API}/paypal/create`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify($cart.toJson()),
                    });
                    if (response.ok) {
                        return await response.text();
                    } else {
                        alert("Fehler beim Erstellen der Bestellung.");
                    }
                },
                async onApprove(data, actions) {
                    const response = await fetch(`${API}/paypal/capture`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "text/plain",
                        },
                        body: data.orderID,
                    });

                    if (response.ok) {
                        paypalOrderId = data.orderID;
                    }
                },
            })
            .render("#paypal-button-container");
    });
</script>

{#if $cart}
    <dialog
        class="w-full h-full p-2 md:p-16 rounded-md overflow-x-hidden"
        bind:this={dialog}
        on:close={() => (show = false)}
    >
        <button
            class="absolute top-0 px-4 py-2 text-center align-middle right-0 text-red-500 hover:text-white hover:bg-red-500 rounded-bl-md rounded-tr-md"
            on:click={(event) => {
                dialog.close();
            }}>Abbrechen</button
        >
        <main class="w-100">
            <h2 class="text-xl font-headers">Bestellung Abschließen</h2>

            <section class="border rounded-md p-8 mt-8">
                <Cart allowModify={false} allowCheckout={false} />
            </section>

            {#if canDeliver}
                <Toggle
                    labels={["Lieferung", "Abholen"]}
                    bind:toggle={delivery}
                />
            {:else}
                <div class="px-16 py-8 my-8 rounded-md bg-red-300">
                    <p class="text-red-700">
                        <strong>WICHTIG: </strong> Der Mindestbestellwert für Lieferungen
                        beträgt 8€. Sie müssen ihre Bestellung selber abholen, da
                        Sie den Mindestbestellwert nicht erreicht haben.
                    </p>
                </div>
            {/if}

            <form
                class="border-top w-max-100"
                onsubmit="return false"
                on:submit={(event) => {
                    event.preventDefault();
                    if (canSubmit) {
                        submitOrder();
                    }
                    return false;
                }}
            >
                <fieldset class="border rounded-md p-8">
                    <legend>Kontaktdaten</legend>

                    <CheckoutInput
                        bind:value={name}
                        maxlength={50}
                        label="Name"
                        id="name"
                        validate={(value) => {
                            if (value.length < 2) {
                                return "Bitte geben Sie einen Namen ein.";
                            }
                        }}
                    />

                    <CheckoutInput
                        label="Telefon"
                        id="tel"
                        bind:value={tel}
                        maxlength={30}
                        validate={(value) => {
                            if (!value.match(/^\+?\d+$/) || value.length < 5) {
                                return "Bitte geben Sie eine gültige Telefonnummer ein.";
                            }
                        }}
                    />
                </fieldset>

                {#if delivery}
                    <fieldset class="border rounded-md p-8">
                        <legend>Adresse</legend>
                        <CheckoutInput
                            id="street"
                            maxlength={50}
                            bind:value={street}
                            label="Straße"
                        />

                        <CheckoutInput
                            id="houseNumber"
                            label="Hausnummer"
                            bind:value={houseNumber}
                            maxlength={10}
                        />
                    </fieldset>
                {/if}
                {#if !paypalOrderId}
                    <fieldset class="border rounded-md p-8">
                        <legend> Zahlungsmethode </legend>
                        <Toggle labels={["Bar", "Online"]} bind:toggle={cash} />

                        <div
                            id="paypal-button-container"
                            class={cash ? "hidden" : "block"}
                        ></div>
                    </fieldset>
                {:else}
                    <p class="text-green-700 py-8">
                        ✓ PayPal-Zahlung erfolgreich.
                    </p>
                {/if}

                <fieldset class="border rounded-md">
                    <legend>Anmerkungen</legend>
                    <textarea
                        class="w-full h-full p-4 rounded-md outline-none"
                        bind:value={comment}
                        placeholder="Anmerkungen zur Bestellung"
                    ></textarea>
                </fieldset>
                <fieldset class="border rounded-md">
                    <legend>Lieferzeitpunkt</legend>
                    <Toggle
                        labels={["Vorbestellen", "Schnellstmöglich"]}
                        bind:toggle={wantsDate}
                    />

                    {#if wantsDate}
                        <CheckoutInput
                            label="Datum"
                            id="date"
                            type="datetime-local"
                            bind:value={date}
                            validate={(value) => {
                                if (!isValidDate(value)) {
                                    return "Dieses Datum ist ungültig oder befindet sich außerhalb der Öffnungszeiten.";
                                }
                            }}
                        />
                    {/if}
                </fieldset>
                <button
                    class="w-full mt-8 bg-green-500 hover:bg-green-800 disabled:bg-gray-100 disabled:hover:bg-gray-100 disabled:text-black text-white p-4 rounded-md"
                    type="submit"
                    disabled={!canSubmit}
                >
                    Bestellen
                </button>
            </form>
        </main>
    </dialog>
{/if}
